import './assets/css/linearicons.css'
import './assets/css/bootstrap.css'
import './assets/css/magnific-popup.css'
import './assets/css/nice-select.css'
import './assets/css/animate.min.css'
import './assets/css/main.css'


import { Routes, Route } from "react-router-dom";

//layouts
import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";
import Homepage from './components/Homepage/Homepage';
import About from './components/About/About'
import Events from './components/Events/Events'
import Gallery from './components/Gallery/Gallery'
import Contact from './components/Contact/Contact'
import Demo from './components/Demo/Demo'
import Faq from './components/FAQ/Faq'
import UnderMaintainance from './components/Maintainance/UnderMaintainance'

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route exact path="About" element={<About />} />
        <Route exact path="events" element={<Events />} />
        <Route exact path="gallery" element={<Gallery />} />
        <Route exact path="contact" element={<Contact />} />
        <Route exact path="demo" element={<Demo />} />
        <Route exact path="faq" element={<Faq />} />
        <Route exact path="*" element={<UnderMaintainance />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
