import React from "react";
import './About.css'

//images
import { Helmet } from "react-helmet";
import about_banner from '../../assets/img/newHome/about_banner.png'
import assignment_solution from '../../assets/img/newHome/assignment_solution.png'
import excellent_quality from '../../assets/img/newHome/excellent_quality.png'
import high_quality from '../../assets/img/newHome/high_quality.png'


function About() {

  return (
    <>
      <Helmet>
        <title>QuickAssignment - About</title>
      </Helmet>

      <div className="home_banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 col-xl-7" style={{ marginTop: 75 }}>
              <p className="header_font" style={{ textAlign: 'left', fontSize: 55 }}>Get expert <span style={{ color: '#F7631B' }}>Assignment help </span>today!</p>
              <p className="header_sub_font mb-4">Would you like to do your assignments with no risks, no hiccups, and also score high? Now you don’t need to take risks to get top grades in class because Quick Assignment experts are here to help you out with any paper,assignment,or thesis for any subject.  Our team has more than 300+ experts to guide you. At Quick Assignment, you will be spoilt for choices with options for assignments. From complicated assignments to challenging homework tasks we ensure to help you out with any kind of task and also offer the best quality services that are also affordable. </p>
              <div style={{ display: 'flex' }}>
                <a className="get_started" href="/contact">Contact Us&nbsp;&nbsp;
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                    <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62622 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="white" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
              <img src={about_banner} alt="banner_img" className="banner_image" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-orange">
        <div className="row">
          <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-12 about_second_sec">
            <img src={excellent_quality} alt="excellent_quality" />
            <p className="request_text">We offer excellent quality assignment solutions at an affordable price.</p>
          </div>
          <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-12 about_second_sec">
            <img src={assignment_solution} alt="assignment_solution" />
            <p className="request_text">We use analytical and critical thinking skills when developing an assignment solution.</p>
          </div>
          <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-12 about_second_sec">
            <img src={high_quality} alt="high_quality" />
            <p className="request_text">We deliver high-quality academic content for more than 38 subjects.</p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mt-5" style={{ padding: 10 }}>
          <p className="about_text">Now you don’t need to take stress about your assignments as the experts will help you to complete the assignments on time. Our team of writers provides accurate and reliable assignment solutions that will help you to attain remarkable grades in class. If you wish to avail of our services then all you need to do is to provide your details or contact us through the mentioned email id. </p>
          <p className="about_text mt-2">Quick Assignment is helping thousands of students to ease their academic pressure due to the increasing number of assignments,tasks, homework, etc. Quick Assignment is a professional assignment writing service for various courses like MBA tasks, Information technology,finance,and computer science, you name it and we have it. Be it a research paper or thesis or essay, the experts are well-trained and skilled to do any task efficiently while also ensuring that you get high grades. </p>
        </div>
      </div>

    </>
  );
}

export default About;
