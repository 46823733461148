// export const BaseUrlApi = "http://localhost/quickassignment_backend/api/"
export const BaseUrlApi = "https://admin.quickassignment.co.uk/api/"

export const ContactUrl = BaseUrlApi + 'contact'

export const FreeDemo = BaseUrlApi + 'request_demo'

export const HelpRequest = BaseUrlApi + 'help_request'

// export const BaseUrl = "http://localhost:3000/"
export const BaseUrl = "https://quickassignment.co.uk/"


