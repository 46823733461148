import React from 'react'
import { Link } from 'react-router-dom'
import g1 from '../../assets/image/gallery1.jpg'
import g2 from '../../assets/image/gallery2.jpeg'
import g3 from '../../assets/image/gallery3.jpg'
import g4 from '../../assets/image/gallery4.jpg'
import g5 from '../../assets/image/gallery5.jpg'
import g6 from '../../assets/image/gallery6.jpg'
import g7 from '../../assets/img/gallery/g7.jpg'
import { Helmet } from 'react-helmet'

function Gallery() {
	return (
		<>
			<Helmet>
				<title>QuickAssignment - Gallery</title>
			</Helmet>

			{/* <!-- start banner Area --> */}
			<section class="banner-area relative about-banner" id="home">
				<div class="overlay overlay-bg"></div>
				<div class="container">
					<div class="row d-flex align-items-center justify-content-center">
						<div class="about-content col-lg-12">
							<h1 class="text-white">
								Gallery
							</h1>
							<p class="text-white link-nav"><Link to='/'>Home </Link>  <span class="lnr lnr-arrow-right"></span>  <Link>Gallery</Link></p>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- End banner Area -->	 */}


			{/* <!-- Start gallery Area --> */}
			<section class="gallery-area section-gap">
				<div class="container">
					<div class="row">
						<div class="col-lg-7">
							<div class="single-imgs relative">
								<div class="overlay overlay-bg"></div>
								<div class="relative">
									<img class="img-fluid" src={g1} alt="" />
								</div>
							</div>
						</div>
						<div class="col-lg-5">
							<div class="single-imgs relative">
								<div class="overlay overlay-bg"></div>
								<div class="relative">
									<img class="img-fluid" src={g2} alt="" style={{ height: "414px", objectFit: "cover" }} />
								</div>
							</div>
						</div>
						<div class="col-lg-4">
							<div class="single-imgs relative">
								<div class="overlay overlay-bg"></div>
								<div class="relative">
									<img class="img-fluid" src={g3} alt="" />
								</div>
							</div>
						</div>
						<div class="col-lg-4">
							<div class="single-imgs relative">
								<div class="overlay overlay-bg"></div>
								<div class="relative">
									<img class="img-fluid" src={g4} alt="" />
								</div>
							</div>
						</div>
						<div class="col-lg-4">
							<div class="single-imgs relative">
								<div class="overlay overlay-bg"></div>
								<div class="relative">
									<img class="img-fluid" src={g5} alt="" />
								</div>
							</div>
						</div>
						<div class="col-lg-5">
							<div class="single-imgs relative">
								<div class="overlay overlay-bg"></div>
								<div class="relative">
									<img class="img-fluid" src={g6} alt="" style={{ objectFit: "cover", height: "242px" }} />
								</div>
							</div>
						</div>
						<div class="col-lg-7">
							<div class="single-imgs relative">
								<div class="overlay overlay-bg"></div>
								<div class="relative">
									<img class="img-fluid" src={g7} alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- End gallery Area --> */}


		</>
	)
}

export default Gallery
