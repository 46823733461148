import React from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import * as Constant from '../Constant'
import { ToastContainer, toast } from "react-toastify";

export class Demo extends React.Component {

    constructor() {
        super();

        this.state = {
            phone: '',
            name: '',
            country_id: '',
            email: '',

            nameError: null,
            emailError: null,
            phoneError: null,
            country_idError: null,

            loading: false
        }
    }

    handleChangeName = event => {
        const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
        this.setState({ name: result })
    };

    handleChangePhone = event => {
        const result = event.target.value.replace(/[^0-9]/gi, '');
        this.setState({ phone: result })
    };

    validate = () => {
        let nameError = '';
        let emailError = '';
        let phoneError = '';
        let country_idError = '';

        if (!this.state.name) {
            nameError = "Please enter name"
        }

        var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
        if (!this.state.email) {
            emailError = "Please enter email address.";
        } else {
            if (!reg.test(this.state.email)) {
                emailError = "Please enter a valid email address.";
            }
        }

        if (!this.state.phone) {
            phoneError = "Please enter contact number"
        }
        if (this.state.country_id.length == 0) {
            country_idError = "Please select country"
        }

        if (nameError || emailError || phoneError || country_idError) {
            this.setState({
                nameError,
                emailError,
                phoneError,
                country_idError
            });

            return false;
        }

        return true;
    }

    onSubmit = () => {
        if (this.validate()) {
            this.setState({
                nameError: null,
                emailError: null,
                phoneError: null,
            })
            this.onEnrollDemo();
        }
    }


    onEnrollDemo = async () => {
        this.setState({ loading: true })

        const formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("email", this.state.email);
        formData.append("phone", this.state.phone);
        formData.append("country_id", this.state.country_id);

        await fetch(Constant.FreeDemo, {
            method: 'POST',
            body: formData,
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({ loading: false })
                    this.setState({
                        name: '',
                        email: '',
                        phone: ''

                    })

                    // toast.success('Your request forwarded successfully..', {
                    //     position: 'top-center',
                    //     autoClose: 5000,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    // });

                    window.location.href = Constant.BaseUrl
                } else {
                    this.setState({ isLoading: false })
                }
            })
    }

    render() {
        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    fadeSpeed={10}
                    text="Message Sending..."
                >
                    <Helmet>
                        <title>QuickAssignment - Demo Request</title>
                    </Helmet>

                    <section class="banner-area relative about-banner" id="home">
                        <div class="overlay overlay-bg"></div>
                        <div class="container">
                            <div class="row d-flex align-items-center justify-content-center">
                                <div class="about-content col-lg-12">
                                    <h1 class="text-white">Demo Request</h1>
                                    <p class="text-white link-nav">
                                        <Link to="/">Home </Link>
                                        <span class="lnr lnr-arrow-right"></span>
                                        <NavLink to="/demo">Demo Request</NavLink>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <ToastContainer />

                    <section class="section-gap" style={{ marginBottom: 30, marginTop: 30 }}>
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row ">
                                        <div className="col-md-6 mb-3">
                                            <input
                                                type="text"
                                                placeholder="Enter name"
                                                class="form-control"
                                                name="name"
                                                value={this.state.name}
                                                onChange={this.handleChangeName}
                                                required
                                            />
                                            {!!this.state.nameError && (
                                                <text style={{ marginLeft: "10px", color: 'red' }}>
                                                    {this.state.nameError}
                                                </text>
                                            )}
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <input
                                                type="text"
                                                placeholder="Enter phone no."
                                                class="form-control"
                                                name="phone"
                                                value={this.state.phone}
                                                onChange={this.handleChangePhone}
                                                minLength="10"
                                                maxLength="10"
                                                required
                                            />
                                            {!!this.state.phoneError && (
                                                <text style={{ marginLeft: "10px", color: 'red' }}>
                                                    {this.state.phoneError}
                                                </text>
                                            )}
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <input
                                                type="text"
                                                value={this.state.email}
                                                onChange={(event) =>
                                                    this.setState({ email: event.target.value })
                                                }
                                                placeholder="Enter email"
                                                class="form-control"
                                                name="email"
                                                required
                                            />
                                            {!!this.state.emailError && (
                                                <text style={{ marginLeft: "10px", color: 'red' }}>
                                                    {this.state.emailError}
                                                </text>
                                            )}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <select name="country_id" id="country_id" value={this.state.country_id} onChange={(event) => this.setState({ country_id: event.target.value })} className="form-control" style={{ height: 38 }}>
                                                <option value="">Select Country</option>
                                                <option value="13" >Australia</option>
                                                <option value="99" >India</option>
                                                <option value="225" >United Kingdom</option>
                                                <option value="226" >United States</option>
                                            </select>
                                            {!!this.state.country_idError && (
                                                <text style={{ marginLeft: "10px", color: 'red' }}>
                                                    {this.state.country_idError}
                                                </text>
                                            )}
                                        </div>
                                        <div className="col-md-6"></div>

                                        <div className="col-md-6 mb-3">
                                            <button
                                                class="genric-btn primary"
                                                style={{ float: "right" }}
                                                onClick={() => this.onSubmit()}
                                            >
                                                Send Message
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </LoadingOverlay>
            </>
        )
    }
}

export default Demo;