import React from "react";

//images
import "owl.carousel/dist/assets/owl.carousel.css";

import "./homepage.css";
import { Helmet } from "react-helmet";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

import accounting from '../../assets/img/newHome/accounting.png'
import banner_img from '../../assets/img/newHome/banner_img.png'
import business_mgnt from '../../assets/img/newHome/business_mgnt.png'
import economics from '../../assets/img/newHome/economics.png'
import english_litreture from '../../assets/img/newHome/english_litreture.png'
import finance from '../../assets/img/newHome/finance.png'
import helthcare from '../../assets/img/newHome/helthcare.png'
import hospitality from '../../assets/img/newHome/hospitality.png'
import it from '../../assets/img/newHome/it.png'
import marketing from '../../assets/img/newHome/marketing.png'
import networking_expert from '../../assets/img/newHome/networking_expert.png'
import nursing from '../../assets/img/newHome/nursing.png'
import tourism from '../../assets/img/newHome/tourism.png'
import map from '../../assets/img/newHome/map.png'
import LoadingOverlay from "react-loading-overlay";

import OwlCarousel from 'react-owl-carousel2';
import * as Constant from '../Constant'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const options = {
  items: 2,
  autoplay: true,
  loop: true
};

class Homepage extends React.Component {

  constructor() {
    super();

    this.state = {
      myArray: [
        { id: 1, name: 'Alex', description: 'Great work! Thank you Quick Assignment for making my life easier by helping me with the assignments. ' },
        { id: 2, name: 'Miranda', description: 'I appreciate that the writer considered all the case studies before developing the final report. I scored high grades. Thank You! ' },
        { id: 3, name: 'Ryan', description: 'The team is very helpful they were available for help 24 *7.' },
        { id: 4, name: 'Owen', description: 'I could not find a writer who could help me complete my task in a period of 6 hours. But the team at Quick assignment made it possible and I was saved. Thanks a lot.' },
        { id: 4, name: 'Harry', description: 'The paper quality was really good, and most importantly it was submitted in the mentioned time frame.' },
        { id: 6, name: 'Brad', description: "I was quite happy with my assignment's outcome because of the accuracy and 100% unique content I scored higher than my teammates." },
      ],

      fullName: "",
      email: "",
      phone: "",
      requirement: "",
      isLoading: false,
      attachment: "",

      fullNameError: null,
      emailError: null,
      phoneError: null,
      requirementError: null,
      attachmentError: null
    }

  }

  validate = () => {
    let fullNameError = "";
    let emailError = "";
    let phoneError = "";
    let requirementError = "";
    let attachmentError = "";

    if (!this.state.fullName) {
      fullNameError = "Please enter full name.";
    }
    var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
    if (!this.state.email) {
      emailError = "Please enter email address.";
    } else {
      if (!reg.test(this.state.email)) {
        emailError = "Please enter a valid email address.";
      }
    }

    if (!this.state.phone) {
      phoneError = "Please phone number.";
    }

    if (!this.state.requirement) {
      requirementError = "Please enter your requirement.";
    }

    if (!this.state.attachment) {
      attachmentError = "Please attach file.";
    }

    if (fullNameError || emailError || phoneError || requirementError || attachmentError) {
      this.setState({
        fullNameError,
        emailError,
        phoneError,
        requirementError,
        attachmentError
      })

      return false
    }

    return true;

  }

  handleChangePhone = event => {
    const result = event.target.value.replace(/[^0-9]/gi, '');
    this.setState({ phone: result })
  };

  handleChangeFirstName = event => {
    const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
    this.setState({
      fullName: result,
    })
  };

  goToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  onSubmit = () => {
    if (this.validate()) {
      this.setState({
        fullNameError: null,
        emailError: null,
        phoneError: null,
        requirementError: null,
        attachmentError: null
      })
      this.onSendHelp();
    }

  }

  onSendHelp = async () => {
    this.setState({ isLoading: true });

    const formData = new FormData();
    formData.append("fullname", this.state.fullName);
    formData.append("email", this.state.email);
    formData.append("phone", this.state.phone);
    formData.append("requirement", this.state.requirement);
    formData.append("attachment", this.state.attachment);

    await fetch(Constant.HelpRequest, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            fullName: "",
            email: "",
            phone: "",
            requirement: "",
            attachment: ""
          });
          this.setState({ isLoading: false });

          toast.success('Your request submitted successfully', {
            position: 'top-center',
            width: 100,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })

  }

  render() {
    return (
      <>
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          fadeSpeed={10}
          text="Message Sending..."
        >
          <Helmet>
            <title>QuickAssignment - Home</title>
          </Helmet>

          <div className="home_banner">
            <div className="row " style={{ paddingLeft: '18%', paddingRight: '18%' }}>
              <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 col-xl-7" >
                <img src={banner_img} alt="banner_image" className="banner_img" />
              </div>
              <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5" style={{ marginTop: 25, marginBottom: 30 }}>
                <div className="black_bg p-4">
                  <p className="form_heading">Assignment Help Request</p>
                  <div className="row ">
                    <div className="col-md-12 col-lg-12 col-sm-12">
                      <div className="form-group">
                        <input type="text" className="form-control field" value={this.state.fullName} onChange={(e) => this.handleChangeFirstName(e)} placeholder="Name" />
                        {!!this.state.fullNameError && (
                          <text style={{ color: 'red' }}>
                            {this.state.fullNameError}
                          </text>
                        )}
                      </div>

                      <div className="form-group">
                        <input type="text" className="form-control field" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} placeholder="Email" />
                        {!!this.state.emailError && (
                          <text style={{ color: 'red' }}>
                            {this.state.emailError}
                          </text>
                        )}
                      </div>

                      <div className="form-group">
                        <input type="text" className="form-control field" maxLength={11} value={this.state.phone} onChange={(e) => this.handleChangePhone(e)} placeholder="Phone No." />
                        {!!this.state.phoneError && (
                          <text style={{ color: 'red' }}>
                            {this.state.phoneError}
                          </text>
                        )}
                      </div>

                      <div className="form-group">
                        <input type="text" className="form-control field" value={this.state.requirement} onChange={(e) => this.setState({ requirement: e.target.value })} placeholder="Write Your Requirement" />
                        {!!this.state.requirementError && (
                          <text style={{ color: 'red' }}>
                            {this.state.requirementError}
                          </text>
                        )}
                      </div>

                      <div className="form-group">
                        <input type="file" className="form-control field" onChange={(event) => this.setState({
                          attachment: event.target.files[0]
                        })} style={{ paddingTop: 9 }} />
                        {!!this.state.attachmentError && (
                          <text style={{ color: "red", marginLeft: "10px" }}>
                            {this.state.attachmentError}
                          </text>
                        )}
                      </div>
                      <div className="form-group" style={{ marginTop: 30, display: 'flex', justifyContent: 'flex-end' }}>
                        <a className="get_started" onClick={this.onSubmit}>Send Message
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>

          <div className="bg-orange">
            <div className="container">
              <div className="row ">
                <p className="header_font mb-3" style={{ fontSize: 40 }}>Looking for a high quality reliable online assignment help?</p>
                <p className="header_sub_font">Quick Assignment is here to help you out with all the assignments and tasks that too at affordable rates. Quick assignment is a trusted and reliable essay service that helps to connect learners with experts to attain assistance for their assignments and score high grades. Every expert or professional at Quick Assignments has a unique rating based on their knowledge, skills, and experience with assignment writing services. </p>
              </div>
            </div>
          </div>

          <div className="bg-black">
            <div className="row">
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
                <p className="number">12,958</p>
                <p className="request_text">Student requests</p>
              </div>
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
                <p className="number">2,468</p>
                <p className="request_text">Assignments completed</p>
              </div>
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
                <p className="number">2,378</p>
                <p className="request_text">Technical projects</p>
              </div>
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
                <p className="number">1,273</p>
                <p className="request_text">In house professionals</p>
              </div>
            </div>
          </div>

          <div class="second_banner ">
            <p className="grades mt-5">How does<span style={{ color: '#F7631B' }}>&nbsp;it work?</span></p>
            <div className="container">
              <div class="row ">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-xl-4">
                  <div class="bg-light rounded mb-4 grade_block">
                    <p className="work_text">Place an order</p>
                    <p className="work_sub_text">To complete the order process, the team members need your details related to the order. All you need to do is fill out a short form and specify your assignment needs &requirements, along with the deadline. </p>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-xl-4">
                  <div class="bg-light rounded mb-4 grade_block">
                    <p className="work_text">Track the progress</p>
                    <p className="work_sub_text">You can speak with the expert assigned to your task,and you can check the sample paper provided by the writer to understand the progress and quality of the assignment that will be provided to you. </p>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-xl-4">
                  <div class="bg-light rounded mb-4 grade_block" >
                    <p className="work_text">Get your high-quality solution</p>
                    <p className="work_sub_text">Your order will be ready in a jiffy. Once you receive it, you can check if it meets your needs while also getting the changes done if required, based on the tutor's feedback. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="expert_bg">
            <p className="grades pt-5 " style={{ color: '#fff' }}>Get <span style={{ color: '#F7631B' }}>the experts</span> you need</p>
            <p className="experience pb-3" style={{ color: '#fff' }}>We have highly educated and qualified writers to help students.</p>
            <div className="container">
              <div className="row">
                <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                  <img src={accounting} className="mx-auto" alt="accounting" />
                  <p className="expert_text">Accounting</p>
                </div>
                <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                  <img src={finance} className="mx-auto" alt="finance" />
                  <p className="expert_text">Finance</p>
                </div>
                <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                  <img src={it} className="mx-auto" alt="it" />
                  <p className="expert_text">IT</p>
                </div>
                <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                  <img src={english_litreture} className="mx-auto" alt="English Literature" />
                  <p className="expert_text">English<br></br>
                    Literature</p>
                </div>
                <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                  <img src={business_mgnt} className="mx-auto" alt="business_mgnt" />
                  <p className="expert_text">Business
                    Management</p>
                </div>
                <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                  <img src={marketing} className="mx-auto" alt="marketing" />
                  <p className="expert_text">Marketing</p>
                </div>
                <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                  <img src={nursing} className="mx-auto" alt="nursing" />
                  <p className="expert_text">Nursing</p>
                </div>
                <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                  <img src={economics} className="mx-auto" alt="economics" />
                  <p className="expert_text">Economics</p>
                </div>
                <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                  <img src={helthcare} className="mx-auto" alt="helthcare" />
                  <p className="expert_text" style={{ marginTop: 31 }}>Health care</p>
                </div>
                <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                  <img src={networking_expert} className="mx-auto" alt="networking_expert" />
                  <p className="expert_text">Networking</p>
                </div>
                <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                  <img src={tourism} className="mx-auto" alt="tourism" />
                  <p className="expert_text">Tourism</p>
                </div>
                <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                  <img src={hospitality} className="mx-auto" alt="hospitality" />
                  <p className="expert_text">Hospitality</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <p className="grades mt-5">Our<span style={{ color: '#F7631B' }}>&nbsp;Happy Clients</span></p>
            <p className="experience pb-3">We have more than 4000 happy clients across the globe.</p>

            <div className="row">
              <div className="col-md-5 col-lg-5 col-sm-12 col-xs-12 col-xl-5 mt-4">
                <img src={map} alt="map" className="banner_img" />
              </div>
              <div className="col-md-7 col-lg-7 col-sm-12 col-xs-12 col-xl-7 mt-4 mx-auto align-items-center justify-content-center">
                <div>
                  <OwlCarousel options={options}>
                    {this.state.myArray.map((item) => (
                      <div className="row" style={{ paddingTop: 55 }} key={item.id}>
                        <div className="col-md-12">
                          <div class="testimonial-item">
                            <div class="rounded testi_card">
                              <h3 className="test_heading">{item.name}</h3>
                              <p className="test_sub_text">{item.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>

          <TawkMessengerReact
            propertyId="5dac0c49df22d91339a00d85"
            widgetId="default"
          />

        </LoadingOverlay >
      </>
    );
  }
}

export default Homepage;
